<template>
  <a-col span="24">
    <a-card title="作业管理" style="text-align: left">
      <a-button type="primary" @click="add()">添加作业</a-button>
      <a-table
        :columns="columns"
        :data-source="tableData"
        :row-key="(record, index) => ++index"
      >
        <span slot="dealerRange" slot-scope="record">
          <a-button size="small" type="primary" @click="edit(record)"
            >编辑</a-button
          >
        </span>
        <span slot="action" slot-scope="record">
          <a-tag @click="previewAddress(record.id)">预览地址/二维码</a-tag>
        </span>
      </a-table>
    </a-card>
    <a-modal
      v-model="workData.isShow"
      :title="workData.name !== '' ? '编辑作业' : '添加作业'"
      :width="600"
      @ok="sub()"
      @cancel="workData.isShow = false"
    >
      <a-row>
        <a-col :span="13">
          <a-input
            placeholder="作业名称"
            style="margin-top: 10px; margin-bottom: 10px"
            v-model="workData.name"
          ></a-input>
          <a-textarea
            placeholder="作业要求"
            v-model="workData.description"
            :auto-size="{ minRows: 2, maxRows: 10 }"
          ></a-textarea>
        </a-col>
        <a-col :span="10" :offset="1">
          <p>作业要求预览:</p>
          <div class="up" v-html="workData.description"></div>
        </a-col>
      </a-row>
    </a-modal>
    <a-modal
      v-model="preview.isShow"
      :title="'预览地址'"
      :width="500"
      :footer="null"
      @cancel="preview.isShow = false"
    >
      <a-input v-model="preview.url" />
      <p>
        <img :src="preview.img" width="400" alt="" />
      </p>
    </a-modal>
  </a-col>
</template>

<script>
import axios from "axios";

export default {
  name: "JobManagement.vue",
  data() {
    return {
      columns: [
        {
          title: "id",
          key: "id",
          dataIndex: "id",
        },
        {
          title: "作业名称",
          key: "name",
          dataIndex: "name",
        },
        {
          title: "作业要求",
          key: "dealerRange",
          scopedSlots: { customRender: "dealerRange" },
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      workData: {
        isShow: false,
        description: "",
        id: 0,
        name: "",
      },
      preview: {
        isShow: false,
        url: "",
        img: "",
      },
      tableData: [],
    };
  },
  mounted() {
    this.getTableData();
  },
  methods: {
    async edit(data) {
      const con = await this.getTableDescription(data.id);
      this.workData.isShow = true;
      this.workData.description = con[0].description;
      this.workData.id = con[0].id;
      this.workData.name = con[0].name;
    },
    add() {
      this.workData.id = 0;
      this.workData.name = "";
      this.workData.description = "";
      this.workData.isShow = true;
    },
    async getTableData() {
      let res = await this.$Http["RecordTypeQuery"]();
      this.tableData = res.data;
    },
    async getTableDescription(id) {
      let res = await this.$Http["RecordTypeGet"]({ id });
      return res.data;
    },
    async sub() {
      let arr = {};
      arr["name"] = this.workData.name;
      arr["description"] = this.workData.description;
      if (this.workData.id !== 0) {
        arr["id"] = this.workData.id;
      }
      let res = await this.$Http["RecordTypeSave"](arr);
      if (!res.isError) {
        this.$message.success(
          this.workData.name +
            (Number(this.workData.id) > 0 ? "修改" : "添加") +
            "成功!"
        );
        this.workData.isShow = false;
        await this.getTableData();
      } else {
        this.$message.error(res["errMsg"]);
        this.workData.isShow = false;
      }
    },
    async previewAddress(id) {
      this.preview.isShow = true;
      this.preview.url =
        window.location.origin + "/cj/f/show2021/about?typeId=" + id;
      this.copyHandle(this.preview.url);
      axios
        .get("/cj/apis/Image/QrCodeGenerate?content=" + this.preview.url, {
          responseType: "blob", //这里是声明期望返回的数据类型，为blob
        })
        .then((response) => {
          this.preview.img = window.URL.createObjectURL(response.data); //这里调用window的URL方法
        });
    },
    copyHandle(content) {
      let copy = (e) => {
        e.preventDefault();
        e.clipboardData.setData("text/plain", content);
        document.removeEventListener("copy", copy);
        this.$message.success(this.preview.url + " 已放入剪贴板,可直接去粘贴!");
      };
      document.addEventListener("copy", copy);
      document.execCommand("Copy");
    },
  },
};
</script>

<style lang="less" scoped>
.ant-row {
  width: 100%;
  max-width: 1330px;
  margin: 0 auto !important;
}

.head {
  height: 40px;
  margin-top: 16px;
  line-height: 40px;

  .ant-col:first-child {
    font-size: 18px;
    font-weight: bold;
  }
}

.content {
  margin-top: 20px;
}
</style>
<style lang="less">
.up {
  p {
    clear: both;
    line-height: 20px;
    font-size: 14px;

    span {
      float: left;
      display: block;
      color: #006baa;
      font-size: 22px;
      position: relative;
      left: -4px;
    }
    b {
      display: block;
      font-weight: 400;
      float: left;
      width: 90%;
      margin-bottom: 10px;
    }
    &:first-child {
      padding-top: 30px;
    }
    &:last-child {
      padding-bottom: 20px;
    }
  }
}
</style>
