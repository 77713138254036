<template>
  <section>
    <van-tabs>
      <van-tab title="生产排单">
        <p></p>
        <van-form>
          <van-cell title="订单号" :value="OrderSn"></van-cell>
          <van-cell title="程序数量">
            <!-- 使用 right-icon 插槽来自定义右侧图标 -->
            <template #right-icon>
              <van-stepper v-model="OrderNum" style="padding-right: 18%" />
            </template>
          </van-cell>
          <van-cell title="选择工艺">
            <!-- 使用 right-icon 插槽来自定义右侧图标 -->
            <template #right-icon>
              <span style="padding-right: 20px">{{ LibraryName }}</span>
              <van-button
                type="info"
                style="margin-right: 18%"
                @click.stop="view"
                >选择工艺
              </van-button>
            </template>
          </van-cell>
          <div style="margin: 16px">
            <van-button
              round
              block
              type="info"
              native-type="submit"
              @click="onSubmit"
              >下单制作
            </van-button>
          </div>
        </van-form>
        <div class="">
          <a-table
            :columns="columns"
            :row-key="(record, index) => ++index"
            :data-source="OrderData"
          >
          </a-table>
        </div>
      </van-tab>
      <van-tab title="历史订单">
        <a-table
          :row-key="(record, index) => ++index"
          :columns="columns"
          :data-source="tableData"
        >
        </a-table>
      </van-tab>
    </van-tabs>
    <a-modal
      :title="null"
      :visible="visible"
      :footer="null"
      @cancel="visible = false"
    >
      <van-tabs>
        <van-tab title="铁画">
          <div class="list">
            <ul>
              <li v-for="(item, i) in listData.data1" :key="i">
                <van-image
                  width="140"
                  height="140"
                  @click="Library(item)"
                  :src="url + item['OrderDbjImg']"
                  :alt="item['LibraryName']"
                />
                <p>{{ item["LibraryName"] }}</p>
              </li>
            </ul>
          </div>
        </van-tab>
        <van-tab title="棋子">
          <div class="list">
            <ul>
              <li v-for="(item, i) in listData.data2" :key="i">
                <van-image
                  width="140"
                  height="140"
                  @click="Library(item)"
                  :src="url + item['LibraryImg']"
                  :alt="item['LibraryName']"
                />
                <p>{{ item["LibraryName"] }}</p>
              </li>
            </ul>
          </div>
        </van-tab>
        <van-tab title="鼠标">
          <div class="list">
            <ul>
              <li v-for="(item, i) in listData.data3" :key="i">
                <van-image
                  width="140"
                  height="140"
                  @click="Library(item)"
                  :src="url + item['LibraryImg']"
                  :alt="item['LibraryName']"
                />
                <p>{{ item["LibraryName"] }}</p>
              </li>
            </ul>
          </div>
        </van-tab>
        <van-tab title="笔筒">
          <div class="list">
            <ul>
              <li v-for="(item, i) in listData.data4" :key="i">
                <van-image
                  width="140"
                  height="140"
                  @click="Library(item)"
                  :src="url + item['LibraryImg']"
                  :alt="item['LibraryName']"
                />
                <p>{{ item["LibraryName"] }}</p>
              </li>
            </ul>
          </div>
        </van-tab>
      </van-tabs>
    </a-modal>
  </section>
</template>

<script>
import moment from "moment";
import { Toast } from "vant";

export default {
  name: "HistoryOrder",
  data() {
    return {
      moment,
      visible: false,
      columns: [
        {
          title: "订单号",
          dataIndex: "OrderSn",
          key: "OrderSn",
        },
        {
          title: "下单时间",
          dataIndex: "OrderTime",
          key: "OrderTime",
        },
        {
          title: "下单数量",
          dataIndex: "OrderNum",
          key: "OrderNum",
        },
      ],
      paginationParam: {
        current: 1,
        total: 0,
        pageSize: 20,
      },
      tableData: [],
      listData: {
        data1: [],
        data2: [],
        data3: [],
        data4: [],
      },
      OrderData: [],
      OrderSn: "",
      OrderNum: 1,
      // url: "http://192.168.0.100/upload/",
      url: "http://whitmes.vs-robot.com/upload/",
      LibraryId: 0,
      LibraryName: "",
      LibraryImg: "",
    };
  },
  created() {
    this.gethistoryData();
    this.getOrderSn();
  },
  methods: {
    async onSubmit() {
      if (!this.LibraryName) {
        Toast("没有选择工艺");
        return false;
      }
      let res = await this.$Http.addMesOrder({
        OrderSn: this.OrderSn.toString(),
        OrderNumber: this.OrderNum,
        OrderProcessName: this.LibraryName,
        OrderUser: "admin",
      });
      if (res["Msg"] === "OK") {
        Toast(this.OrderSn + "添加成功!");
        this.LibraryName = "";
      }
    },
    async view() {
      this.visible = true;
      await this.getCraftsData();
    },
    Library(data) {
      console.log(data);
      this.LibraryId = data["Id"];
      this.LibraryName = data.LibraryName;
      this.LibraryImg = data["OrderDbjImg"];
      this.visible = false;
    },
    async getProgramData() {},
    async gethistoryData() {
      let res = await this.$Http.historyMesOrder();
      this.tableData = res["RtnData"];
      let rbs = await this.$Http.mesOrder({ OrderState: 0 });
      this.OrderData = rbs["RtnData"];
    },

    getOrderSn() {
      this.OrderNum = 1;
      const today = new Date();
      let seconds;
      let minutes;
      let month;
      let day;
      let hours;
      month =
        today.getMonth() < 9
          ? "0" + (today.getMonth() + 1).toString()
          : (today.getMonth() + 1).toString();
      day =
        today.getDate() < 10
          ? "0" + today.getDate().toString()
          : today.getDate().toString();
      hours =
        today.getHours() < 10
          ? "0" + today.getHours().toString()
          : today.getHours().toString();
      minutes =
        today.getMinutes() < 10
          ? "0" + today.getMinutes().toString()
          : today.getMinutes().toString();
      seconds =
        today.getSeconds() < 10
          ? "0" + today.getSeconds().toString()
          : today.getSeconds();
      setTimeout(async () => {
        this.OrderSn = (
          today.getFullYear() +
          month +
          day +
          hours +
          minutes +
          seconds
        ).toString();
        await this.getProgramData();
      }, 300);
    },
    // 工艺列表
    async getCraftsData() {
      let res = await this.$Http.mesProgram();
      const list = res["RtnData"];
      list.forEach((d) => {
        if (d["TypeId"] === 0) {
          this.listData.data1.push(d);
        }
        if (d["TypeId"] === 1) {
          this.listData.data2.push(d);
        }
        if (d["TypeId"] === 2) {
          this.listData.data3.push(d);
        }
        if (d["TypeId"] === 3) {
          this.listData.data4.push(d);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .ant-table-pagination.ant-pagination {
  float: none !important;
}

.van-cell__title,
.van-cell__value {
  text-align: left;
}

.list {
  padding: 20px 0 50px 0;
  height: 60vh;
  overflow: auto;

  ul {
    li {
      float: left;
      width: 50%;
    }
  }
}
</style>
