<template>
  <a-row style="padding-top: 15px">
    <van-nav-bar
      title="移动互联智能工厂"
      left-arrow
      @click-left="onClickLeft"
    />
    <router-view />
    <foot-bar :selected-keys="keys" />
  </a-row>
</template>
<script>
import FootBar from "./Footer";
import { Toast } from "vant";

export default {
  components: { FootBar },
  data() {
    return {
      keys: "/b/show2021/home",
      paramsData: {},
    };
  },
  mounted() {},
  methods: {
    onClickLeft() {
      if (this.$route.fullPath === "/about") {
        Toast("已经是最后一页了");
      } else {
        window.history.go(-1);
      }
    },
  },
};
</script>
<style lang="less">
.ant-row {
  width: 100%;
  max-width: 1330px;
  margin: 0 auto !important;
}

.head {
  height: 40px;
  margin-top: 16px;
  line-height: 40px;

  .ant-col:first-child {
    font-size: 18px;
    font-weight: bold;
  }
}

.content {
  margin-top: 20px;
}
</style>
