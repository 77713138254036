<template>
  <section>
    <van-tabs class="list">
      <van-tab title="数控">
        <img src="../assets/img/d1.png" alt="" />
        <van-cell-group>
          <van-cell
            v-for="(item,i) of deviceData.data1" :key="i++"
            :title="item.label"
            :value="item.value"
          />
        </van-cell-group>
      </van-tab>
      <van-tab title="切割">
        <img src="../assets/img/d2.png" alt="" />
        <van-cell-group>
          <van-cell
            v-for="(item,i) of deviceData.data2" :key="i++"
            :title="item.label"
            :value="item.value"
          />
        </van-cell-group>
      </van-tab>
      <van-tab title="装配">
        <img src="../assets/img/d3.png" alt="" />
        <van-cell-group>
          <van-cell
            v-for="(item,i) of deviceData.data3" :key="i++"
            :title="item.label"
            :value="item.value"
          />
        </van-cell-group>
      </van-tab>
      <van-tab title="仓库">
        <img src="../assets/img/d4_4.png" alt="" />
        <van-cell-group>
          <van-cell
            v-for="(item,i) of deviceData.data4" :key="i++"
            :title="item.label"
            :value="item.value"
          />
        </van-cell-group>
      </van-tab>
      <van-tab title="协作">
        <img src="../assets/img/d5.png" alt="" />
        <van-cell-group>
          <van-cell
            v-for="(item,i) of deviceData.data5" :key="i++"
            :title="item.label"
            :value="item.value"
          />
        </van-cell-group>
      </van-tab>
    </van-tabs>
  </section>
</template>

<script>
export default {
  name: "echartView",
  data() {
    return {
      deviceData: {
        data1: [],
        data2: [],
        data3: [],
        data4: [],
        data5: [],
      },
    };
  },
  async created() {
    await this.getData();
  },
  methods: {
    async getData() {
      const ras = await this.$Http.deviceList();
      this.deviceData.data1 = [];
      this.deviceData.data2 = [];
      this.deviceData.data3 = [];
      this.deviceData.data4 = [];
      this.deviceData.data5 = [];
      const list = ras["RtnData"];
      list.forEach((d) => {
        if (d["ItemId"] === "1") {
          this.deviceData.data1.push({
            label: d["ItemTitle"],
            value: d["ItemValue"],
          });
        }
        if (d["ItemId"] === "2") {
          this.deviceData.data2.push({
            label: d["ItemTitle"],
            value: d["ItemValue"],
          });
        }
        if (d["ItemId"] === "3") {
          this.deviceData.data3.push({
            label: d["ItemTitle"],
            value: d["ItemValue"],
          });
        }
        if (d["ItemId"] === "4") {
          this.deviceData.data4.push({
            label: d["ItemTitle"],
            value: d["ItemValue"],
          });
        }
        if (d["ItemId"] === "5") {
          this.deviceData.data5.push({
            label: d["ItemTitle"],
            value: d["ItemValue"],
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.list {
  img {
    width: 80%;
    padding: 30px 0;
  }
}

.van-cell__value {
  text-align: center;
}
</style>
