import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.less";
import "vant/lib/index.css";
import Vant from "vant";
import Http from "./http/http";
import VCharts from "v-charts";

Vue.config.productionTip = false;
Vue.use(Antd).use(Vant).use(VCharts);
Vue.prototype.$Http = Http;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
