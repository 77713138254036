const UPLOAD_API = {
  // 登陆
  login: {
    method: "post",
    url: "/login",
  },
  // 当前进度
  orderProgress: {
    method: "get",
    url: "/orderProgress",
  },
  // 当前订单
  mesCommandOrder: {
    method: "get",
    url: "/mesCommandOrder",
  },
  // 上传
  upload: {
    method: "post",
    url: "/upload?=9",
  },
  // 工艺库
  mesProgram: {
    method: "get",
    url: "/mesProgram",
  },
  // 历史订单
  historyMesOrder: {
    method: "get",
    url: "/historyMesOrder",
  },
  mesOrder: {
    method: "get",
    url: "/mesOrder",
  },
  deviceList: {// 查询设备列表
    method: "get",
    url: "/deviceList",
  },
  // 仓储
  depotStatic: {
    method: "get",
    url: "/depotStatic",
  },
  // 添加订单
  addMesOrder: {
    method: "put",
    url: "/addMesOrder",
  },
  // 未执行订单
  produceOrder: {
    method: "get",
    url: "/produceOrder",
  },
};
export default UPLOAD_API;
