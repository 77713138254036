import axios from "axios";
import UPLOAD_API from "./upload";

const baseUrl = "http://110.42.129.53:8097/";
function getToken() {
  return localStorage.getItem("token") ?? "";
}
// service 循环遍历输出不同的请求方法
let instance = axios.create({
  baseURL: baseUrl,
  timeout: 60000,
});
const Http = { baseUrl }; // 包裹请求方法的容器

const myApi = [UPLOAD_API];

for (let ii in myApi) {
  // 请求格式/参数的统一
  for (let key in myApi[ii]) {
    let api = myApi[ii][key]; // url method
    // async 作用：避免进入回调地狱
    Http[key] = async function (
      params, // 请求参数 get：url，put，post，patch（data），delete：url
      isFormData = false, // 标识是否是form-data请求
      config = {} // 配置参数
    ) {
      let newParams = {};

      //  content-type是否是form-data的判断
      if (params && isFormData) {
        newParams = new FormData();
        for (let i in params) {
          newParams.append(i, params[i]);
        }
      } else {
        newParams = params;
      }
      // 不同请求的判断
      let response = {}; // 请求的返回值
      if (
        api.method === "put" ||
        api.method === "post" ||
        api.method === "patch"
      ) {
        try {
          response = await instance[api.method](api.url, newParams, config);
        } catch (err) {
          response = err;
        }
      } else if (api.method === "delete" || api.method === "get") {
        config.params = newParams;
        try {
          response = await instance[api.method](api.url, config);
          if (typeof response === undefined) {
            this.$message.error("远程错误，请联系管理员！");
          }
        } catch (err) {
          this.$message.error("远程错误，请联系管理员！");
          response = err;
        }
      }
      return response; // 返回响应值
    };
  }
}

// 拦截器的添加
// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    // 每次请求接口时，调用进度条
    // 发起请求前做些什么
    if (getToken()) {
      config.headers["Authorization"] = "Bearer " + getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    return config;
  },
  () => {
    // 请求错误
  }
);
// 响应拦截器
instance.interceptors.response.use(
  (res) => {
    // 请求成功
    return res.data;
  },
  (error) => {
    console.log(89, error);
    if (error) {
      window.location = "/login";
    }
  }
);

export default Http;
