<template>
  <van-tabbar v-model="active" :border="true" :placeholder="true">
    <van-tabbar-item icon="home-o" to="/about">订单状态</van-tabbar-item>
    <van-tabbar-item icon="search" to="/echart">系统监控</van-tabbar-item>
    <van-tabbar-item icon="friends-o" to="/history" dot
      >订单监控</van-tabbar-item
    >
    <van-tabbar-item icon="setting-o" to="/list">工艺库</van-tabbar-item>
  </van-tabbar>
</template>
<script>
export default {
  name: "FootBar.vue",
  props: {
    selectedKeys: String,
  },
  data() {
    return {
      active: 0,
      menuData: [],
    };
  },
  created() {
    this.getRoutes();
  },
  methods: {
    getRoutes() {},
  },
  watch: {
    selectedKeys: {
      handler: function () {
        this.getRoutes();
      },
      deep: true,
    },
  },
};
</script>

<style>
.van-tabbar-item--active {
  background: #003972 !important;
  color: #fff !important;
}
</style>
