import Vue from "vue";
import VueRouter from "vue-router";
import EchartView from "../views/echartView";
import List from "../views/List";
import HistoryOrder from "../views/HistoryOrder";
import Login from "../views/Login";
import JobManagement from "../views/backstage/JobManagement";
import Layout from "../views/backstage/layout";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/about",
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/page",
    name: "layout",
    component: Layout,
    children: [
      {
        path: "/about",
        name: "首页",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/About.vue"),
      },
      {
        path: "/echart",
        name: "echart",
        component: EchartView,
      },
      {
        path: "/history",
        name: "history",
        component: HistoryOrder,
      },
      {
        path: "/list",
        name: "list",
        component: List,
      },
      {
        path: "/jobManagement",
        name: "作业管理",
        component: JobManagement,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
