<template>
  <section style="padding: 30vh 10px 0 10px">
    <van-row type="flex" justify="center">
      <van-col span="24">
        <van-form @submit="onSubmit">
          <van-field
            v-model="username"
            name="用户名"
            label="用户名"
            placeholder="用户名"
            :rules="[{ required: true, message: '请填写用户名' }]"
          />
          <van-field
            v-model="password"
            type="password"
            name="密码"
            label="密码"
            placeholder="密码"
            :rules="[{ required: true, message: '请填写密码' }]"
          />
          <div style="margin: 16px">
            <van-button round block type="info" native-type="submit"
            >提交
            </van-button
            >
          </div>
        </van-form>
      </van-col>
    </van-row>
  </section>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      username: "admin",
      password: "123456"
    };
  },
  created() {
  },
  methods: {
    async onSubmit() {
      let res = await this.$Http.login({
        password: this.password,
        User: this.username,
      });
      localStorage.setItem("token", res.token);
      await this.$router.push("/about");
    }
  }
};
</script>

<style scoped></style>
