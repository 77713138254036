<template>
  <section>
    <van-tabs>
      <van-tab title="铁画">
        <div class="list">
          <ul>
            <li v-for="(item, i) in listData.data1" :key="i">
              <van-image
                width="140"
                height="140"
                @click="showPopup(item)"
                :src="url + item['OrderDbjImg']"
                :alt="item['LibraryName']"
              />
              <p>{{ item["LibraryName"] }}</p>
            </li>
          </ul>
        </div>
      </van-tab>
      <van-tab title="棋子">
        <div class="list">
          <ul>
            <li v-for="(item, i) in listData.data2" :key="i">
              <van-image
                width="140"
                height="140"
                @click="showPopup(item)"
                :src="url + item['LibraryImg']"
                :alt="item['LibraryName']"
              />
              <p>{{ item["LibraryName"] }}</p>
            </li>
          </ul>
        </div>
      </van-tab>
      <van-tab title="鼠标">
        <div class="list">
          <ul>
            <li v-for="(item, i) in listData.data3" :key="i">
              <van-image
                width="140"
                height="140"
                @click="showPopup(item)"
                :src="url + item['LibraryImg']"
                :alt="item['LibraryName']"
              />
              <p>{{ item["LibraryName"] }}</p>
            </li>
          </ul>
        </div>
      </van-tab>
      <van-tab title="笔筒">
        <div class="list">
          <ul>
            <li v-for="(item, i) in listData.data4" :key="i">
              <van-image
                width="140"
                height="140"
                @click="showPopup(item)"
                :src="url + item['LibraryImg']"
                :alt="item['LibraryName']"
              />
              <p>{{ item["LibraryName"] }}</p>
            </li>
          </ul>
        </div>
      </van-tab>
    </van-tabs>
    <van-popup v-model="show">
      <van-cell-group>
        <p>
          <van-image
            width="140"
            height="140"
            :src="url + showData['LibraryImg']"
            :alt="showData['LibraryName']"
          />
        </p>
        <van-cell title="名称" :value="showData['LibraryName']" />
        <van-cell title="CNC程序" :value="showData['OrderCnc']" />
        <van-cell title="上色机程序" :value="showData['OrderSsj']" />
        <van-cell title="打标机程序" :value="showData['OrderDbj']" />
      </van-cell-group>
    </van-popup>
  </section>
</template>

<script>
export default {
  name: "List",
  data() {
    return {
      listData: {
        data1: [],
        data2: [],
        data3: [],
        data4: [],
      },
      list: [{}],
      //  url: "http://192.168.0.100/upload/",
      url: "http://whitmes.vs-robot.com/upload/",
      show: false,
      showData: {}
    };
  },
  async created() {
    //   this.url = window.location.origin;
    await this.getData();
  },
  methods: {
    async getData() {
      let res = await this.$Http.mesProgram();
      res["RtnData"].forEach((d) => {
        if(d["LibraryName"] !== "") this.list.push(d);
      });
      const list = res["RtnData"];
      list.forEach((d) => {
        if(d["TypeId"] === 0) {
          this.listData.data1.push(d);
        }
        if(d["TypeId"] === 1) {
          this.listData.data2.push(d);
        }
        if(d["TypeId"] === 2) {
          this.listData.data3.push(d);
        }
        if(d["TypeId"] === 3) {
          this.listData.data4.push(d);
        }
      });
    },
    showPopup(data) {
      this.show = true;
      this.showData = data;
    }
  }
};
</script>

<style lang="less" scoped>
.list {
  padding: 20px 0 50px 0;
  overflow: hidden;

  ul {
    li {
      float: left;
      width: 50%;
      }
    }
  }

.van-popup--center {
  width: 100%;
  }

.van-cell__value {
  text-align: center;
  }
</style>
